<template>
  <div class="reason-info-card mx-auto">
    <div class="reason-card-items">
      <img :src="require(`@/assets/images/icons/reason-info/ic_${path}.png`)" />
      <div class="item-title">
        <div class="title">
          {{ title }}
        </div>
        <div class="subtitle">
          {{ subtitle }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReasonInfoCard",
  props: {
    path: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    subtitle: {
      type: String,
      default: () => "",
    },
  },
};
</script>
<style lang="scss">
.reason-info-card {
  display: block;
  .reason-card-items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .item-title {
      text-align: start;
      padding-left: 10px;

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
      }

      .subtitle {
        font-family: "Avenir Next LT W05 Regular";
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
</style>
